import React from "react"
import Layout from "../components/layout"
import MainImage from "../components/mainImage"
import building from "../../static/contact-icon-building.png"
import person from "../../static/contact-icon-person.png"
import contactBg from "../../static/contact/mi-bg.png"
import { Helmet } from "react-helmet"

export default function contact(props) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>pecan pipeline - Contact Us</title>
      </Helmet>
      <MainImage header="Contact Us" src={contactBg} />
      <div className="page-content-container contact-page">
        <div className="page-card column fs2 part1">
          <img
            alt="building icon"
            className="image-for-titles"
            src={building}
          />
          <h2 className="page-content-title fs5">Headquarters</h2>
          <div className="card-info-box">
            <h4 className="header-4 fs2">Phone</h4>
            (713) 651-7000
          </div>
          <div className="card-info-box">
            <h4 className="header-4 fs2">Toll Free</h4>
            1(877) 363-3647
          </div>
          <div className="card-info-box">
            <h4 className="header-4 fs2">Mailing</h4>
            Pecan Pipeline Company
            <br />
            Pecan Pipeline (Wyoming), Inc.
            <br />
            Pecan Pipeline (North Dakota), Inc.
            <br /> P.O. Box 4362
            <br />
            Houston, TX 77210-4362
          </div>
          <div className="card-info-box">
            <h4 className="header-4 fs2">Shipping</h4>
            Pecan Pipeline Company
            <br /> Pecan Pipeline (North Dakota), Inc.
            <br /> Pecan Pipeline (Wyoming), Inc.
            <br /> 1111 Bagby, Sky Lobby 2<br />
            Houston, TX 77002
          </div>
        </div>

        <div className="page-card column fs2 part2">
          <img alt="person icon" className="image-for-titles" src={person} />
          <h2 className="page-content-title fs5">Key Contact</h2>
          <div className="card-info-box">
            <h4 className="header-4 fs2">Lance Terveen</h4>
            Senior Vice President, Marketing Operations
          </div>
          <div className="card-info-box">
            <h4 className="header-4 fs2">Phone</h4>
            (713) 651-7000
          </div>
        </div>

        <div className="page-card column fs2 part3">
          <h2 className="page-content-title fs5">Questions</h2>
          <div className="card-content">
            Producers who are interested in providing gas for this gathering
            system, please email
            <a
              className="card-content-link-highlight"
              href="mailto:publicawareness@eogresources.com"
            >
              publicawareness@eogresour…
            </a>
            All other inquiries, please email
            <a
              className="card-content-link-highlight"
              href="mailto:publicawareness@eogresources.com"
            >
              publicawareness@eogresour…
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}
